import React from 'react';
import NotFoundBlock from '../components/NotFoundBlock';

const NotFound: React.FC = () => {
    return (
        <>
            <NotFoundBlock />
        </>
    );
};

export default NotFound;
