import './index.scss';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, } from "react-router-dom";
import { Provider } from 'react-redux';

import { store } from './redux/store';
import App from './App';

const rootElem = document.getElementById('root');

if(rootElem) {
	const root = ReactDOM.createRoot(rootElem);
	root.render(
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	
	);
}



